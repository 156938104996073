import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  PrismicRichText,
  SliceComponentProps,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { PrismicNextImage } from '@prismicio/next';
import { ChevronDown } from '@/components/Unknown/Icons';
import styles from './infographicStyles.module.scss';
import { useState, useEffect, useRef } from 'react';

/**
 * Props for `Infographic`.
 */
export type InfographicProps = SliceComponentProps<Content.InfographicSlice>;

/**
 * Component for "Infographic" Slices.
 */
const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-center text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  heading3: ({ children }) => (
    <h3 className="text-balance font-bold ~lg:~text-arc-xl/arc-2xl xl:min-h-[90px]">
      {children}
    </h3>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl">
      {children}
    </p>
  ),
};

const Infographic = ({ slice }: InfographicProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [firstChildHeight, setFirstChildHeight] = useState<number | null>(null);
  const [secondChildCenterOffset, setSecondChildCenterOffset] = useState<
    number | null
  >(null);
  useEffect(() => {
    if (parentRef.current) {
      if (parentRef.current.firstElementChild) {
        const firstChild = parentRef.current.firstElementChild as HTMLElement;
        setFirstChildHeight(firstChild.offsetHeight);
      }

      if (parentRef.current.children.length > 1) {
        const secondChild = parentRef.current.children[1] as HTMLElement;
        const secondChildCenter =
          secondChild.offsetTop + secondChild.offsetHeight / 2;
        setSecondChildCenterOffset(secondChildCenter);
        document.documentElement.style.setProperty(
          '--bg-line-offset',
          `${secondChildCenter - 8}px`,
        );
      }
    }
  }, []);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <article className="flex flex-col ~gap-10/20">
          <div className="o-6">
            <PrismicRichText
              field={slice.primary.heading}
              components={component}
            />
            {slice.primary.show_sub_heading && (
              <PrismicRichText
                field={slice.primary.sub_heading}
                components={component}
              />
            )}
          </div>
          <PrismicNextImage
            field={slice.primary.desktop_infographic}
            className="mx-auto hidden w-[70%] xl:block"
            fallbackAlt=""
          />

          <div
            className={`relative flex flex-col gap-3 pb-14 before:absolute ~/md:~pr-10/12 xl:hidden ${styles['arc-steps']}`}
            ref={parentRef}
          >
            {slice.primary.accordion.map((item, index) => {
              if (item.is_accordion_item) {
                return (
                  <details
                    key={index}
                    className={`relative rounded-full px-8 py-4 before:absolute open:rounded-3xl [&_svg]:open:-rotate-180 ${styles['arc-step-item']}`}
                    onToggle={(e) => {
                      if (
                        e.currentTarget.parentElement?.firstChild ===
                        e.currentTarget
                      ) {
                        if (e.currentTarget.open) {
                          document.documentElement.style.setProperty(
                            '--bg-line-offset',
                            `${e.currentTarget.offsetHeight + (secondChildCenterOffset ? secondChildCenterOffset - 8 : 104) - (firstChildHeight || 0)}px`,
                          );
                        } else {
                          document.documentElement.style.setProperty(
                            '--bg-line-offset',
                            `${secondChildCenterOffset ? secondChildCenterOffset - 8 : 104}px`,
                          );
                        }
                      }
                    }}
                    style={{
                      backgroundColor: item.background_color || 'inherit',
                      color: item.text_color || 'inherit',
                    }}
                  >
                    <summary className="flex list-none items-center justify-between">
                      <p className="text-left font-bold ~/md:~text-arc-base/arc-xl md:flex-1 md:text-center">
                        {item.title}
                      </p>
                      <span className="text-white">
                        <ChevronDown />
                      </span>
                    </summary>
                    <div className="flex items-center justify-between gap-4 py-4 align-middle md:justify-center">
                      {item.content_text && (
                        <p className="~/md:~text-arc-base/arc-xl">
                          {item.content_text}
                        </p>
                      )}
                      {item.content_image && (
                        <PrismicNextImage
                          field={item.content_image}
                          className="aspect-video max-w-[40%] object-contain only:mx-auto md:max-w-[30%]"
                          fallbackAlt=""
                        />
                      )}
                    </div>
                  </details>
                );
              }

              return (
                <div
                  key={index}
                  className={`relative rounded-full px-8 py-4 before:absolute ${styles['arc-step-item']}`}
                  style={{
                    backgroundColor: item.background_color || 'inherit',
                    color: item.text_color || 'inherit',
                  }}
                >
                  <p className="text-left font-bold ~/md:~text-arc-base/arc-xl md:text-center">
                    {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        </article>
      </Container>
    </section>
  );
};

export default Infographic;
